import React from "react"
import { graphql, Link  } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Img from "../../components/Elements/Img"
import SectionContainer from "../../components/SectionContainer"
import VideoPlayer from "../../components/VideoPlayer/index"
import { Container, Row, Col } from "../../components/Grid"
import {
  SectionBody,
  AccentHeader,
  PageTitle,
} from "../../components/Elements/index"

const WorkLink = styled(props => <Link {...props} />)`
  color: #414141;
  &:active {
    color: #414141;
  }

  &:visited {
    color: #414141;
  }
`

const LightBlueDivider = styled.div`
div{
  background: #88c6eb;
}
`

const BlueDivider = styled.div`
div{
  background: #223d7a;
}
`


const Footer = styled.footer`
  background: #6a115a;
  display: flex;
  height: 120px;
  align-items: center;
`

const RevomeMarginImage = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const RevomeMarginVideo = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const celebration = ({ data }) => (
  <Layout>
    <ThemeProvider
      theme={{
        backgroundColor: "#FFFFFF",
        primaryColor: "#414141",
        dividerColor: "#6a115a;",
        invert: {
          backgroundColor: "#5d6062",
          dividerColor: "#6a115a;",
        },
      }}
    >
      <>
        <Seo title="CAMZYOS Celebration | Best Launch, Best Rare Disease & Best Professional categories" />
        <SectionContainer>
          <Container>
            <AccentHeader size="large" background="#ffffff">
              <WorkLink to="/">
                  The Work
                </WorkLink>
            </AccentHeader>
            <div style={{ paddingBottom: "30px" }}>
            <PageTitle style={{ color: "#78c800" }}>Celebration<br/><small></small></PageTitle>
            </div>
            <Row>
              <Col md={8}>
                <LightBlueDivider>
                  <AccentHeader>The Situation</AccentHeader>
                </LightBlueDivider>
                <SectionBody size="regular">
                  Obstructive hypertrophic cardiomyopathy (HCM) is a rare heart condition. Until recently, there was no effective way to treat the disease other than to manage symptoms.   <br />
                  <br />
                  At long last, a new treatment was created to address the underlying cause of obstructive HCM. Doctors and their patients had been waiting far too long for this news, they were tired of being overlooked, and they wanted real advancement in the treatment of the disease.  <br /><br />
                  Knowing the launch of CAMZYOS would be met with great fanfare, we set out to create a launch campaign that would reflect the enthusiasm and yearning in the marketplace. <br/><br/>
                  

                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noBottomMargin>
          <div style={{ backgroundColor: "#cacbcc", padding: "60px 0"}}>
          <Container>
            <Img fluid={data.image1.childImageSharp.fluid} />
            <Row>
              <Col md={4}>
                <Img fluid={data.image2.childImageSharp.fluid} />
              </Col>
              <Col md={4}>
                <Img fluid={data.image3.childImageSharp.fluid} />
              </Col>
            </Row>
          </Container> 
          </div>         
        </SectionContainer>


        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", backgroundColor: "#5d6062", color: "#ffffff" }}>
            <Row>
              <Col md={8}>
                <BlueDivider>
                  <AccentHeader>The Solution</AccentHeader>
                </BlueDivider>
                <SectionBody size="regular">
                    With our core strategic imperative in mind, we launched the "Celebration!" campaign, a multichannel effort to show cardiologists that CAMZYOS was here to reshape the heart—and the marketplace—and finally deliver a treatment with long-term impact for patients.
                  <br />
                  <br />
                  We created a sophisticated, dynamic heart, shimmering with light, energy and excitement—this was not your grandfather’s medical illustration. Dazzling, bold, and impossible to ignore, the holographic display showcases the triumph of CAMZYOS and how it affects the heart—a true first in the treatment of obstructive HCM.
                  <br />
                  <br />
                  To announce this achievement, we placed the heart at center stage: the highlight of an opening ceremony, a star of rock concert, and the guest of honor at an award show.

                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <RevomeMarginVideo>
          <VideoPlayer
            poster={() => (
              <Img fluid={data.videoThumbnail.childImageSharp.fluid} />
            )}
            url="https://player.vimeo.com/video/908396455?h=41183ca1c4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          />
        </RevomeMarginVideo>


        

      

        <SectionContainer>
          <RevomeMarginImage>
            <Container>
              <Img style={{ marginBottom: "60px" }} fluid={data.image4.childImageSharp.fluid} />              
              <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col md={2}>
                <Img fluid={data.image5.childImageSharp.fluid} />
              </Col>
              <Col md={2}>
                <Img fluid={data.image6.childImageSharp.fluid} />
              </Col>
              <Col md={2}>
                <Img fluid={data.image7.childImageSharp.fluid} />
              </Col>
            </Row>
            </Container>
          </RevomeMarginImage>
        </SectionContainer>


        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", backgroundColor: "#5d6062", color: "#ffffff" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>The Success</AccentHeader>                
                <SectionBody size="regular">
                  The “Celebration!” campaign launched on 5 continents in over 40 markets to rousing success. The big unveiling took place at the 2023 ESC Congress in Amsterdam, where it was viewed by thousands of cardiologists. It also scored a high percentage of impressions with doctors who engaged with representatives to learn more about CAMZYOS.<br/><br/>

                    The centerpiece of the trade show experience was a three-dimensional illuminated installation of our campaign heart. It was the talk of the convention and a can't-miss stop on the trade show floor. The installation artfully complemented the brand’s other key launch tactics, including an interactive MOA tool that allowed cardiologists to explore the science behind CAMZYOS and experience how it affects the heart at both the muscular and cellular levels.<br/><br/>

                    Post launch, increasing patient initiation is fueling sales growth and BMS Is preparing to launch in dozens of additional markets in 2024.  


                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image8.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <div style={{ padding: "60px 100px"}}>
          <Container>            
            <Row>
              <Col md={4}>
                <Img fluid={data.image9.childImageSharp.fluid} />
              </Col>
              <Col md={4}>
                <Img fluid={data.image10.childImageSharp.fluid} />
              </Col>
            </Row>
            <Img fluid={data.image11.childImageSharp.fluid} />
          </Container> 
          </div>         
        </SectionContainer>


        <SectionContainer noBottomMargin>
          <div style={{ padding: "100px", backgroundColor: "#cacbcc"}}>
          <Img fluid={data.image12.childImageSharp.fluid} />
          <Container>            
            <Row>
              <Col md={4}>
                <Img fluid={data.image13.childImageSharp.fluid} style={{ marginBottom: "-40px"}} />
              </Col>
              <Col md={4}>
                <Img fluid={data.image14.childImageSharp.fluid} style={{ marginBottom: "-40px"}} />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Img fluid={data.image15.childImageSharp.fluid} style={{ marginBottom: "-40px"}} />
              </Col>
              <Col md={4}>
                <Img fluid={data.image16.childImageSharp.fluid} style={{ marginBottom: "-40px"}} />
              </Col>
            </Row>
          </Container> 
          </div>         
        </SectionContainer>
        
      </>
    </ThemeProvider>
  </Layout>
)

export default celebration

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "2024/celebration/image1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image2: file(relativePath: { eq: "2024/celebration/image2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image3: file(relativePath: { eq: "2024/celebration/image3.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image4: file(relativePath: { eq: "2024/celebration/image4.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image5: file(relativePath: { eq: "2024/celebration/image5.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image6: file(relativePath: { eq: "2024/celebration/image6.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image7: file(relativePath: { eq: "2024/celebration/image7.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image8: file(relativePath: { eq: "2024/celebration/image8.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image9: file(relativePath: { eq: "2024/celebration/image9.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image10: file(relativePath: { eq: "2024/celebration/image10.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image11: file(relativePath: { eq: "2024/celebration/image11.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image12: file(relativePath: { eq: "2024/celebration/image12.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image13: file(relativePath: { eq: "2024/celebration/image13.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image14: file(relativePath: { eq: "2024/celebration/image14.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image15: file(relativePath: { eq: "2024/celebration/image15.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image16: file(relativePath: { eq: "2024/celebration/image16.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    videoThumbnail: file(relativePath: { eq: "2024/celebration/videoposter.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
